import service from '@/utils/request'; // @Tags SysApi
// @Summary 删除客户
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body dbModel.ExaCustomer true "删除客户"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /customer/customer [post]

export var createExaCustomer = function createExaCustomer(data) {
  return service({
    url: "/customer/customer",
    method: 'post',
    data: data
  });
}; // @Tags SysApi
// @Summary 更新客户信息
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body dbModel.ExaCustomer true "更新客户信息"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /customer/customer [put]

export var updateExaCustomer = function updateExaCustomer(data) {
  return service({
    url: "/customer/customer",
    method: 'put',
    data: data
  });
}; // @Tags SysApi
// @Summary 创建客户
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body dbModel.ExaCustomer true "创建客户"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /customer/customer [delete]

export var deleteExaCustomer = function deleteExaCustomer(data) {
  return service({
    url: "/customer/customer",
    method: 'delete',
    data: data
  });
}; // @Tags SysApi
// @Summary 获取单一客户信息
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body dbModel.ExaCustomer true "获取单一客户信息"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /customer/customer [get]

export var getExaCustomer = function getExaCustomer(params) {
  return service({
    url: "/customer/customer",
    method: 'get',
    params: params
  });
}; // @Tags SysApi
// @Summary 获取权限客户列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body modelInterface.PageInfo true "获取权限客户列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /customer/customerList [get]

export var getExaCustomerList = function getExaCustomerList(params) {
  return service({
    url: "/customer/customerList",
    method: 'get',
    params: params
  });
};
/**
 * 客户消息通知列表
 * @param {Object} params { keyword, page, pageSize }
 * @returns 
 */

export var reqNotificationList = function reqNotificationList(params) {
  return service({
    url: '/msg_notify/list',
    method: 'get',
    params: params
  });
};
/**
 * 客户消息通知创建
 * @param {Object} data { keyword, page, pageSize }
 * @returns 
 */

export var reqCreateNotification = function reqCreateNotification(data) {
  return service({
    url: '/msg_notify/create',
    method: 'post',
    data: data
  });
};